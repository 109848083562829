<template>
  <div>
    <b-row v-if="promocodes.length > 0">
      <b-col cols="12">
        <b-card class="my-card m-0">
          <b-table
            striped
            small
            id="journalPromo"
            bordered
            selectable
            selected-variant="primary"
            select-mode="single"
            responsive="sm"
            :tbody-tr-class="rowClass"
            :items="promocodes"
            :fields="fields"
            @row-clicked="clicked"
            @row-dblclicked="editForm"
            @row-contextmenu="handleContextMenuEvent"
            head-row-variant="primary"
          >
            <template #cell(checkbox)="row">
              <b-form-checkbox
                v-model="row.detailsShowing"
                plain
                class="vs-checkbox-con"
                @change="row.toggleDetails"
              >
              </b-form-checkbox>
            </template>
            <template #cell(is_active)="data">
              {{ data.item.is_active == 1 ? "Не блокирован" : "Блокирован" }}
            </template>
          </b-table>
          <vue-context ref="menu">
            <li>
              <b-link class="d-flex align-items-center" @click="editForm">
                <feather-icon icon="Edit3Icon" />
                <span class="ml-75">Изменить</span>
              </b-link>
              <b-link class="d-flex align-items-center" v-b-modal.delete_promo>
                <feather-icon icon="EditIcon" />
                <span class="ml-75">Удалить</span>
              </b-link>
              <b-link class="d-flex align-items-center" v-b-modal.promo_block>
                <feather-icon icon="LockIcon" />
                <span class="ml-75">Заблокировать промокод</span>
              </b-link>
            </li>
          </vue-context>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import VueContext from "vue-context";
import { cellSelect } from "@/utils/TableFunctions/cellSelect";
import { resizeable } from "@/utils/TableFunctions/resizeable";
export default {
  components: {
    VueContext,
  },
  name: "journalPromo",
  props: ["promocodes"],
  data() {
    return {
      id: null,
      fields: [
        { key: "checkbox", label: "", thStyle: { width: "30px" } },
        { key: "id", label: "Id", sortable: true, thStyle: { width: "40px" } },
        {
          key: "promocode_type_name",
          label: "Название типа промо-кода",
          sortable: true,
          thStyle: { width: "40px" },
        },
        {
          key: "count_activation",
          label: "Активация счетчика",
          sortable: true,
          thStyle: { width: "40px" },
        },
        {
          key: "division_name",
          label: "Подразделение",
          sortable: true,
          thStyle: { width: "40px" },
        },
        {
          key: "is_active",
          label: "Статус",
          sortable: true,
          thStyle: { width: "70px" },
        },
        {
          key: "model_type",
          label: "Тип действия",
          sortable: true,
          thStyle: { width: "70px" },
        },
        {
          key: "amount",
          label: "Цена",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "organization_name",
          label: "Организация",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "promo_code",
          label: "Промо-код",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "start_time",
          label: "Дата начала",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "and_time",
          label: "Дата конца",
          sortable: true,
          thStyle: { width: "150px" },
        },
      ],
    };
  },
  mounted() {
    resizeable();
  },
  methods: {
    handleContextMenuEvent(item, index, event) {
      this.id = item.id;
      event.preventDefault();
      this.$refs.menu.open(event);
      this.selectedId = item.id;
      localStorage.setItem("id", this.selectedId);
    },
    rowClass(item, type) {
      if (!item || type !== "row") return;
      if (item.is_active === 10) return "table-danger";
      else if (item.is_active === 1) return "table-success";
      else if (item.is_active === 3) return "table-secondary";
      else if (item.is_active === 2) return "";
    },
    editForm() {
      this.$emit("editForm", this.id);
    },
    clicked(item, index, event) {
      this.id = item.id;
      this.$store.state.filterPlus.tableIndex = index;

      const clickedElement = event.target;
      const clickedCell = clickedElement.closest("td");

      if (!clickedCell) {
        return;
      }

      const columnIndex = Array.from(clickedCell.parentNode.children).indexOf(
        clickedCell
      );

      if (columnIndex < 0 || columnIndex >= this.fields.length) {
        return;
      }

      const clickedField = this.fields[columnIndex];
      this.$store.state.filterPlus.keyFilter = clickedField.key;
      this.$store.state.filterPlus.filterPlus = event.target.innerHTML.trim();
      cellSelect();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/table.scss";
.my-card {
  padding: 0px;
}
.my-card {
  overflow: auto;
  height: calc(100vh - 92px);
}
@media (max-height: 700px) {
  .my-card {
    overflow: auto;
    height: 530px;
  }
}
</style>
